import React from 'react';
import { css, cx } from 'emotion';
import TextBox from './TextBox';

export default function PinEntry({ className, ...props }) {
  return (
    <TextBox
      {...props}
      className={cx(
        className,
        css`
          text-align: center;
          letter-spacing: 0.1rem;
        `
      )}
    />
  );
}
