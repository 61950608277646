import React from 'react';
import routes, { createRouter } from './routes';
import './App.css';
import styled from '@emotion/styled';
import { id } from './utils';
import NicotineWarning from './components/NicotineWarning'
import LeapLogo from './components/LeapLogo'

const Overlay = styled('div')`
  position: absolute;
  top: 0%;
  box-sizing: border-box;
  bottom: auto;
  width: 100vw;
  max-width: 100%;
  min-height: 100vh;
  height: auto;
  z-index: 200000;
  background: rgba(255, 255, 255, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  max-width: 100%;
`;

Overlay.defaultProps = { className: id('overlay') };

const Content = styled('div')`
  background: white;
  font-family: Helvetica, Arial, sans-serif;
  background: #AA8E3B;
  width: 100%;
  min-height: 100vh;
`;

Content.defaultProps = { className: id('content') };

const Padded = styled('div')`
  //height: auto;
  // width: 100vw;
`;

Padded.defaultProps = { className: id('content-inner') };





export default function App() {
  return (
    <Overlay>
      <Content>
        <NicotineWarning />
        <div className="nicotine-warning-fix nicotine-warning__item-fix nicotine-warning--text-fix nicotineSpace" >
                    WARNING: This product contains nicotine. <br /> 
                    Nicotine is an addictive chemical.
        </div>
        <LeapLogo />
          <Padded> 
            {createRouter(routes, {})}
          </Padded>
      </Content>
    </Overlay>
  );
}
