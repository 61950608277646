import React from 'react';
import styled from 'styled-components';

const BodyText = styled.p`
    color: #fff;
    text-align: center;
`

const BodyContent = ({ children }) => (
    <BodyText>
        {children}
    </BodyText>
)

export default BodyContent;