import React, { useState, useRef } from 'react';
import Heading from '../components/ui/Heading';
import { useMount } from 'react-use';
import { scrollToElement } from '../utils';
import styled from 'styled-components';


const StyledHeading = styled(Heading)`
  color: #fff;
  text-align: center;
`

const ContactAreaText = styled.p`
  color: #fff;
  text-align: center;
`
const FailBody = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`

const ContactEmail = styled.a`
  color: #fff;
  padding: 8px 0;
`


export default function FailedToVerify({
  config,
  location,
  history,
  onLogOutClick
}) {
  // eslint-disable-next-line
  const [errorMessage, setErrorMessage] = useState(null);
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const topRef = useRef();

  useMount(() => {
    scrollToElement(topRef);
    console.warn(config)
  });
  
  return (
    <div style={{height: "100vh"}} ref={topRef}>
    {/* Change this in the app panel. */}
      {/* <Heading h1>{config.customerVerify.lang.identityUploadTitle}</Heading> */}
      <StyledHeading h1>Failed to verify</StyledHeading>

      <ContactAreaText>We were unable to identify you. Please contact us.</ContactAreaText>
      <FailBody>
        <ContactEmail href="support@leapvapor.com">support@leapvapor.com</ContactEmail>
        <ContactAreaText>877-373-0069</ContactAreaText>
      </FailBody>
    </div>
  );
}