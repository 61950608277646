import React from 'react';
import { Route, Switch } from 'react-router';
import { BrowserRouter as Router } from 'react-router-dom';

import PhoneVerify from './pages/PhoneVerify';
import PhoneContinue from './pages/PhoneContinue';
import ConfigurationContext from './context/ConfigurationContext';
import IdentityVerify from './pages/IdentityVerify';
import IdentityComplete from './pages/IdentityComplete';
import IdentityUpload from './pages/IdentityUpload';
import KBA from './pages/KBA';
import FailedToVerify from './pages/FailedToVerify';
import Initial from './pages/Initial';

export default {
  '/phone/verify': opts => <PhoneVerify />,
  '/phone/continue/:continuationToken': opts => <PhoneContinue />,
  '/identity/verify': opts => <IdentityVerify />,
  '/identity/complete': opts => <IdentityComplete />,
  '/identity/upload': opts => <IdentityUpload />,
  '/identity/KBA': opts => <KBA />,
  '/identity/failedtoverify': opts => <FailedToVerify />,

};

export function createRouter(routes, opts) {
  return (
    
    <ConfigurationContext.Consumer>
      {config => (
        <Router basename={`${window.location.pathname}#`}>
          <Switch>
            {Object.entries(routes).map(([path, render], key) => {
              return (
                <Route
                  key={key}
                  path={path}
                  exact
                  render={props =>
                    React.cloneElement(render(opts), {
                      ...props,
                      config,
                      onLogOutClick: function(e) {
                        e.preventDefault();
                        window.location.href = '/login.php?action=logout';
                      }
                    })
                  }
                />
              );
            })}
            <Route render={props => <Initial {...props} config={config} />} />
          </Switch>
        </Router>
      )}
    </ConfigurationContext.Consumer>
  );
}
