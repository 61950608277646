import React from 'react';
import styled from "styled-components";
import { API_ROOT } from '../api.js'

const StyledLogo = styled.img`
    margin: 0 auto;
    display: block;
    margin-top: 4rem!important;
    width: 200px;
    /* margin-top: 61vh; */
    /* margin-bottom: 1vh; */

    @media (min-width: 650px) {
    width: auto;
    margin-bottom: 2vh;
  }

    @media (min-width: 800px) {
  }

    @media (min-width: 1024px) {
        /* margin-bottom: 0; */
        /* margin-bottom: 5vh; */
    }
`

const LeapLogo = () => {
    return (
        <StyledLogo style={{}} src={`${API_ROOT}/assets/storefront-2fa/leapLogo.png`} alt="leap-vapor-logo" />
    )
}

export default LeapLogo