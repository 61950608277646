import React from 'react';
import Select from './Select';
import states from '../../data/states-us';

const sortedStates = Object.entries(states.data.USA)
  .sort((a, b) => (a[1] < b[1] ? -1 : +(a[1] > b[1])))
  .reduce((acc, [k, v]) => {
    acc[k] = v;

    return acc;
  }, {});

export default function StateSelect(props) {
  return (
    <Select {...props}>
      <option value="">Select...</option>
      {Object.entries(sortedStates).map(([code, name], i) => (
        <option key={i} value={name}>
          {name}
        </option>
      ))}
    </Select>
  );
}
