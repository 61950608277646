import React, { useRef } from 'react';
import Heading from '../components/ui/Heading';
import NotificationMessage from '../components/NotificationMessage';
// import { Redirect } from 'react-router';
// import Button from '../components/ui/Button';
// import ButtonActionGroup from '../components/ui/ButtonActionGroup';
// import { Link } from 'react-router-dom';
import { useMount } from 'react-use';
import { scrollToElement } from '../utils';
import KbaQuestion from '../components/kba-questions/KbaQuestion';

export default function KBA({
  config,
  location,
  history,
  onLogOutClick
}) {
  const topRef = useRef();

  useMount(() => {
    scrollToElement(topRef);
  });
  

  // if (!location.state.identity) {
  //   return <Redirect to="/phone/verify" noThrow />;
  // }

  // function handleCloseClick() {
  //   config.instance.onFlowComplete(
  //     location.state.flow_was_completed === undefined
  //       ? true
  //       : location.state.flow_was_completed,
  //     location.state.identity
  //   );
  // }

  return (
    <div style={{height: "100%"}} ref={topRef}>
      <Heading h1>{location.state.title}</Heading>
      {!!location.state.message && (
        <NotificationMessage message={location.state.message} />
      )}

    <KbaQuestion 
      config={config} 
      location={location} 
      history={history}
      onLogOutClick={onLogOutClick}
      />
      
      {/* <ButtonActionGroup>
        <Button type="submit" onClick={handleCloseClick}>
          Close
        </Button>
      </ButtonActionGroup>
      <ButtonActionGroup>
        <Link to="/login.php?action=logout" onClick={onLogOutClick}>
          Log out
        </Link>
      </ButtonActionGroup> */}
    </div>
  );
}
