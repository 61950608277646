import 'react-app-polyfill/ie11';
import 'core-js';

import * as Sentry from '@sentry/browser';
import React from 'react';
import ReactDOM from 'react-dom';
import './styles.css';
import AppContainer from './AppContainer';
import * as serviceWorker from './serviceWorker';
import { createRoot, addStylesheet } from './utils';
import { STORE_HASH } from './globals';

import {
  fetchConfiguration,
  fetchIsVerificationRequired,
  refreshTokenIfNecessary,
  fetchGetVerificationStatus,
  fetchGetCustomerPrefill,
  fetchUpdateCustomerPhoneNumber,
  fetchUpdateCustomerAccountName
} from './api';
import countries from './data/countries';

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN
  });
} else {
  console.warn('Veratad Connector: Sentry is not enabled');
}

let rootContainer = null;

const debugMode = Number(process.env.REACT_APP_DEBUG_VERIFICATION) === 1;

// Cache the configuration fetched for isVerificationRequired/getCustomer calls
let configurationCache = null;

function unmount() {
  ReactDOM.unmountComponentAtNode(rootContainer);
}

/**
 * Runs some transformations on the incoming identity data.
 * Country -> name to ISO3166 alpha 3
 */
function identityTransforms(identity) {
  return {
    ...identity,
    country: lookupCountryByName(identity.country)
  };
}

function lookupCountryByName(countryName) {
  for (let [code, name] of Object.entries(countries.data)) {
    if (name === countryName) {
      return code;
    }
  }

  return 'USA';
}

function verifyPhoneNumber(identity = {}, onPhoneVerified = () => {}) {
  return new Promise((resolve, reject) => {
    window.location.hash = '';

    rootContainer = createRoot();

    ReactDOM.render(
      <AppContainer
        storeHash={STORE_HASH}
        identity={identityTransforms(identity)}
        flow="phone"
        onPhoneVerified={onPhoneVerified}
        onFlowComplete={(verified, identity) => {
          unmount();
          resolve({ verified, identity });
        }}
      />,
      rootContainer
    );
  });
}

function verifyAge(identity = {}) {
  return new Promise((resolve, reject) => {
    window.location.hash = '';

    rootContainer = createRoot();

    ReactDOM.render(
      <AppContainer
        storeHash={STORE_HASH}
        flow="age"
        identity={identityTransforms(identity)}
        onPhoneVerified={() => {}}
        onFlowComplete={(verified, identity) => {
          unmount();
          resolve({ verified, identity });
        }}
      />,
      rootContainer
    );
  });
}

function verifyAll(identity = {}, onPhoneVerified = () => {}) {
  return new Promise((resolve, reject) => {
    window.location.hash = '';

    rootContainer = createRoot();

    ReactDOM.render(
      <AppContainer
        storeHash={STORE_HASH}
        flow="all"
        identity={identityTransforms(identity)}
        onPhoneVerified={onPhoneVerified}
        onFlowComplete={(verified, identity) => {
          unmount();
          resolve({ verified, identity });
        }}
      />,
      rootContainer
    );
  });
}

/**
 * Determine which verifications are required for the logged in customer, if any.
 *
 * @return { age: boolean, phone: boolean } Verifications required
 */
async function isVerificationRequired(customerName, phoneNumber) {
  const { data } =
    configurationCache ||
    (configurationCache = await fetchConfiguration(STORE_HASH));
  const token = await refreshTokenIfNecessary(data.configuration.clientId);

  const { data: verificationData } = await fetchIsVerificationRequired(
    token,
    customerName,
    phoneNumber
  );

  return {
    age: verificationData.is_age_verification_required,
    phone: verificationData.is_phone_verification_required
  };
}

async function getVerificationStatus() {
  const { data } =
    configurationCache ||
    (configurationCache = await fetchConfiguration(STORE_HASH));
  const token = await refreshTokenIfNecessary(data.configuration.clientId);

  const { data: verificationData } = await fetchGetVerificationStatus(token);

  return {
    age: verificationData.is_age_verification_required,
    phone: verificationData.is_phone_verification_required
  };
}

/**
 * Get the `customer` part of thw JWT
 */
async function getCustomer() {
  const { data } =
    configurationCache ||
    (configurationCache = await fetchConfiguration(STORE_HASH));

  try {
    const token = await refreshTokenIfNecessary(data.configuration.clientId);

    return token.customer;
  } catch (e) {
    return null;
  }
}

/**
 * Get prefill data for a customer (currently DOB)
 */
async function getCustomerPrefill() {
  const { data } =
    configurationCache ||
    (configurationCache = await fetchConfiguration(STORE_HASH));

  try {
    const token = await refreshTokenIfNecessary(data.configuration.clientId);

    const prefill = await fetchGetCustomerPrefill(token);

    return prefill.data.fields;
  } catch (e) {
    console.error(e);
    return null;
  }
}

async function updateCustomerPhoneNumber(newPhoneNumber) {
  const { data } =
    configurationCache ||
    (configurationCache = await fetchConfiguration(STORE_HASH));

  const token = await refreshTokenIfNecessary(data.configuration.clientId);

  return await fetchUpdateCustomerPhoneNumber(token, newPhoneNumber);
}

async function updateCustomerAccountName(firstName, lastName) {
  const { data } =
    configurationCache ||
    (configurationCache = await fetchConfiguration(STORE_HASH));

  const token = await refreshTokenIfNecessary(data.configuration.clientId);

  return await fetchUpdateCustomerAccountName(token, firstName, lastName);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

if (!window.its) {
  window.its = {};
}

if (!window.its.veratadCustomer) {
  window.its.veratadCustomer = {
    verifyPhoneNumber,
    verifyAge,
    verifyAll,
    isVerificationRequired,
    getCustomer,
    getCustomerPrefill,
    getVerificationStatus,
    updateCustomerPhoneNumber,
    updateCustomerAccountName
  };
}

addStylesheet();

if (STORE_HASH) {
  if (debugMode) {
    verifyAll({}, v => {
      console.log('completed phone verification');
    }).then(() => {
      console.log('completed all verification');
    });
  }
} else {
  console.error(
    'Unable to initialize customer verification: malformed script tag (missing store hash)'
  );
}
