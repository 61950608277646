import React, { Fragment } from 'react';
import { id } from '../../utils';
import Label from '../ui/Label';
import Button from '../ui/Button';
import FormGroup from '../ui/FormGroup';
import Section from '../ui/Section';
import ButtonActionGroup from '../ui/ButtonActionGroup';
import Grid from 'hedron';
import RadioList from '../ui/RadioList';
import NumberFormat from 'react-number-format';
import { cx, css } from 'emotion';
import { Link } from 'react-router-dom';

export default function PhoneVerificationForm({
  data,
  validation,
  onDataChange,
  onFieldChange,
  onSubmit,
  allowTest,
  onLogOutClick,
  config
}) {
  function handleSubmit(evt) {
    evt.preventDefault();

    onSubmit(data);
  }

  function handleChange(evt) {
    onDataChange({ ...data, [evt.target.name]: evt.target.value });
    onFieldChange(evt.target.name, evt.target.value);
  }

  const methods = [['sms', 'SMS'], ['call', 'Call']];

  if (allowTest) {
    methods.push(['test', 'Test']);
  }

  return (
    <Fragment>
      <form className={id('form-phone-verify')}>
        <Section>
          <Grid.Provider
            breakpoints={{ sm: '-640', md: '+641' }}
            padding="10px"
          >
            <Grid.Bounds
              direction="horizontal"
              sm={{ width: '100%' }}
              md={{ width: '100%' }}
              wrap={true}
            >
              <Grid.Box sm={{ width: '100%' }} md={{ width: '50%' }}>
                <FormGroup validation={validation.phone}>
                  <Label htmlFor={id('phone')}>Phone Number:</Label>
                  <NumberFormat
                    id={id('phone')}
                    format="(###) ###-####"
                    name="phone"
                    value={data.phone}
                    onChange={handleChange}
                    readOnly={!!config.instance.identity.readonly_phone}
                    className={cx(
                      id('text-box'),
                      css`
                        display: inline-block;
                      `,
                      'form-input'
                    )}
                  />
                </FormGroup>
              </Grid.Box>
              <Grid.Box sm={{ width: '100%' }} md={{ width: '100%' }}>
                <FormGroup validation={validation.method}>
                  Send code via:
                  <RadioList
                    options={methods}
                    name="method"
                    selected={data.method}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Grid.Box>
            </Grid.Bounds>
          </Grid.Provider>
        </Section>

        {config.customerVerify.lang.phoneVerifyBodySmall && (
          <p>
            <small>{config.customerVerify.lang.phoneVerifyBodySmall}</small>
          </p>
        )}

        <p>{config.customerVerify.lang.phoneVerifyBodyLower}</p>

        <ButtonActionGroup>
          <Button className="button button-primary" type="submit" onClick={handleSubmit}>
            Send code
          </Button>
        </ButtonActionGroup>
        <ButtonActionGroup>
          <Link className="button button-primary" to="/login.php?action=logout" onClick={onLogOutClick}>
            Log out
          </Link>
        </ButtonActionGroup>
      </form>
    </Fragment>
  );
}
