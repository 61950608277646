import React from 'react';
import styled from 'styled-components';

const WarningContainer = styled.section`
    min-height: 20vh;
    position: fixed;
    top: 0;
    background-color: #000;
    z-index: 1000;
    width: 100%;
    display: flex;
    height: auto!important;
    box-sizing: border-box;
    font-size: 14px;
`
const NicotineWarning = () => {
    return (
        // <WarningContainer className="nicotine-warning__item nicotine-warning-fix">
        //     <WarningText className="nicotine-warning--text">WARNING: This product contains nicotine. <br /> Nicotine is an addictive chemical.</WarningText>
        // </WarningContainer>
        <WarningContainer className="nicotine-warning-fix">
            <div className="nicotine-warning__item-fix">
                <p className="nicotine-warning--text-fix">
                    WARNING: This product contains nicotine. <br /> 
                    Nicotine is an addictive chemical.
                </p>
            </div>
        </WarningContainer>
    )
}

export default NicotineWarning;