function findScriptTag() {
  return document.querySelector('script[src$="veratad-2fa.min.js"]');
}

export const scriptTag = findScriptTag();

export const STORE_HASH =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_DEV_STORE_HASH
    : scriptTag && scriptTag.getAttribute('data-store');

export const CUSTOMER_EMAIL =
  process.env.NODE_ENV === 'development'
    ? process.env.REACT_APP_DEV_CUSTOMER_EMAIL
    : null;
