import React from 'react';
import { FaCheckCircle, FaTimesCircle } from 'react-icons/fa';
import { css, cx } from 'emotion';
import { id } from '../utils';

const messageTypes = {
  success: {
    icon: FaCheckCircle,
    color: '#fff'
  },
  error: {
    icon: FaTimesCircle,
    color: '#fff'
  }
};

export default function NotificationMessage({ message: { type, text, show3tlLink, returnPage } }) {
  const { icon: Icon, color } = messageTypes[type];

  return (
    <div
      className={cx(
        id('notification'),
        css`
          color: ${color};
          display: flex;
          align-items: flex-start;
          justify-content: center;
          margin: 0 auto 1rem auto;
        `
      )}
    >
      <Icon
        size={32}
        style={{
          verticalAlign: 'middle',
          marginRight: '1rem',
          flexGrow: 0,
          flexShrink: 0,
          marginTop: '-8px'
        }}
      />
      <div style={{ textAlign: 'center' }}>
        <div>{text}</div>
        {show3tlLink && 
          <a href={returnPage ? returnPage : 'https://rewards.leapvapor.com/home'}>
            Return to rewards.
          </a>}
      </div>
    </div>
  );
}
