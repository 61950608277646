import React from 'react';
import { css, cx } from 'emotion';
import { id } from '../../utils';
import styled from 'styled-components';

const StyledInput = styled.input`
  background: transparent;
  border: 1px solid #fff;
  border-radius: 4px;
  color: #fff;
  :focus {
    border: 1px solid white;
  }
`

export default function TextBox({ block, className, ...props }) {
  return (
    <StyledInput
      type="text"
      {...props}
      className={cx(
        id('text-box'),
        block
          ? css`
              display: block;
              width: 100%;
            `
          : css`
              display: inline-block;
            `,
        className,
        'form-input'
      )}
    />
  );
}
