import React from 'react';
import Select from './Select';

const months = [
  [1, 'January'],
  [2, 'February'],
  [3, 'March'],
  [4, 'April'],
  [5, 'May'],
  [6, 'June'],
  [7, 'July'],
  [8, 'August'],
  [9, 'September'],
  [10, 'October'],
  [11, 'November'],
  [12, 'December']
];

export default function MonthSelect(props) {
  return (
    <Select {...props}>
      <option value="">{props.defaultLabel || 'Select...'}</option>
      {months.map(([i, month]) => (
        <option style={{color: "black"}} key={i} value={i}>
          {month}
        </option>
      ))}
    </Select>
  );
}
