import React from 'react';
import Label from './Label';
import Radio from './Radio';
import { cx, css } from 'emotion';
import { id } from '../../utils';

export default function RadioList({
  name,
  selected,
  options,
  onChange,
  className,
  ...props
}) {
  return (
    <div
      style={{paddingLeft: "48px"}}
      className={cx(
        css`
          display: inline-block;
        `,
        id('radio-list'),
        className
      )}
      {...props}
    >
      {options.map(([value, label]) => (
        <Label inline key={value}>
          <Radio
            name={name}
            value={value}
            checked={value === selected}
            onChange={onChange}
          />{' '}
          {label}
        </Label>
      ))}
    </div>
  );
}
