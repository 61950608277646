import React, { Fragment, useState, useRef } from 'react';
import Heading from '../../components/ui/Heading';
import LoadingSpinner from '../../components/ui/LoadingSpinner';
import NotificationMessage from '../../components/NotificationMessage';
  // eslint-disable-next-line
import { fetchUploadIdentification, getToken } from '../../api';
import RadioList from '../ui/RadioList'
import { API_ROOT } from '../../api';
import { Redirect } from 'react-router';
import { useMount } from 'react-use';
import { scrollToElement } from '../../utils';
import styled from 'styled-components';
import ContentBackground from '../ContentBackground'
import BodyContent from '../BodyContent';
import { Link } from 'react-router-dom';
import Button from '../ui/Button';
import ButtonActionGroup from '../ui/ButtonActionGroup';

const StyledHeading = styled(Heading)`
  color: #fff;
  text-align: center;
  /* margin-top: 1rem;
  margin-bottom: 0.25rem; */
`
const QuestionNumber = styled.p`
  color: #fff;
  background: #aa8e3b;
  padding: 8px 48px;
  display: inline-block;
`

const QuestionPrompt = styled.p`
  color: #fff;
  padding-left: 40px;
`

const initialFormData = {
  front: null
};

export default function KbaQuestion({
  config,
  location,
  history,
  onLogOutClick
}) {
  // eslint-disable-next-line
  const [data, setData] = useState({ ...initialFormData });
  // eslint-disable-next-line
  const [validation, setValidation] = useState({});
  // eslint-disable-next-line
  const [errorMessage, setErrorMessage] = useState(null);
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const topRef = useRef();
  const [answers, setAnswers] = useState({});

  useMount(() => {
    scrollToElement(topRef);
    console.warn(location.state)
  });
  
  const identity =
    (location.state && location.state.identity) || config.instance.identity;

  if (!location.state.identity) {
    return <Redirect to="/phone/verify" noThrow />;
  }

  const transformedData = location.state.identity.transformedData

  // eslint-disable-next-line
  async function handleSubmit(e) {
    const three_tl_referral = !!sessionStorage['three_tl_referral']; // set based on session storage
    const three_tl_return_page = sessionStorage['three_tl_return_page'];

    e.preventDefault()
    setLoading(true)
    fetch(`${API_ROOT}/app/kba/submit_answers`, {
      method: 'POST',
      body: JSON.stringify({
        answers,
        token: location.state.kba.answers.token,
        customer_token: (await getToken(config.clientId)).token
      })
    })
      .then(res => res.json())
      .then(res => {
        if(res.data.verification.verified) {
          history.push('/identity/complete', {
            identity: { ...identity, ...transformedData },
            title: 'Verification Complete!',
            flow_was_completed: true,
            message: {
              text: "You've been successfully verified",
              show3tlLink: three_tl_referral,
              returnPage: three_tl_return_page,
              type: 'success'
            }
        });
        setLoading(false)
        } else {
          history.push('/identity/FailedToVerify', {
            identity: {...identity, ...transformedData },
          })
          setLoading(false);
        }
        // if(res.data.verification.verified) {
        //   config.instance.onFlowComplete(true, identity)
        // } else {
        //   history.push('/identity/FailedToVerify', {
        //     identity: {...identity, ...transformedData },
        //   })
        //   setLoading(false);
        // }
      })
      .catch(err => console.log(err))
  }

  function handleRadioButton(event, question) {
    setAnswers({ ...answers, [question.id]: [event.target.value] });
  }

  return (
    <div ref={topRef}>
      {/* <Heading h1>{config.customerVerify.lang.identityUploadTitle}</Heading> */}
      <StyledHeading style={{color: "#fff"}} h1>Knowledge Based Authentication</StyledHeading>

      {loading ? (
        <LoadingSpinner message="Loading" />
      ) : (
        <Fragment>
          {(!!errorMessage || !!location.state.message) && (
            <NotificationMessage
              message={errorMessage || location.state.message}
            />
          )}

          {/* <p>{config.customerVerify.lang.identityUploadBody}</p> */}
          <BodyContent>Please answer the following questions to verify your identity</BodyContent>

          <ContentBackground>
          {location.state.kba &&
              <form style={{margin: "24px 0"}} onSubmit={event => handleSubmit(event)}>
                {
                  location.state.kba.questions.map((question, index) => {
                    return (
                      <div style={{margin: "24px 0"}}>
                        <QuestionNumber>Question {index+1} </QuestionNumber>
                        <QuestionPrompt>{question.prompt}</QuestionPrompt>
                          <RadioList
                            name={`q${question.id}`}
                            // using answers from answers state (selected answers)
                            selected={answers[question.id] ? answers[question.id][0] : ''}
                            options={question.answers.map((answer) => [answer.text, answer.text])}
                            onChange={(event) => handleRadioButton(event, question)}
                          />
                      </div>
                    )
            })
                }
                <ButtonActionGroup>
                  <Button className="button button-primary" type="submit" value="submit">Submit</Button>
                </ButtonActionGroup>
                <ButtonActionGroup>
                  <Link className="button button-primary"  to="/login.php?action=logout" onClick={onLogOutClick}>
                    Log out
                  </Link>
                </ButtonActionGroup>
              </form>
          }
          </ContentBackground>
        </Fragment>
      )}
    </div>
  );
}
