import React from 'react';
import Select from './Select';

function buildRange(min, max) {
  const range = [];
  for (let i = min; i <= max; i++) {
    range.push(i);
  }
  return range;
}

export default function NumericRangeSelect({
  min = 0,
  max = 0,
  reverse = false,
  ...props
}) {
  const range = reverse ? buildRange(min, max).reverse() : buildRange(min, max);
  return (
    <Select {...props}>
      <option value="">{props.defaultLabel || 'Select...'}</option>
      {range.map((year, i) => (
        <option style={{color: "black"}} value={year} key={i}>
          {year}
        </option>
      ))}
    </Select>
  );
}
