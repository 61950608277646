import React from 'react';
import { css, cx } from 'emotion';
import { id } from '../../utils';

export default function Label({ inline, className, ...props }) {
  return (
    <label
      style={{color: "white"}}
      {...props}
      className={cx(
        id('label'),
        className,
        inline
          ? css`
              display: inline-block;
              margin-left: 1rem;
            `
          : css`
              display: block;
            `,
        css`
          padding-bottom: 0.5rem;
        `,
        'form-label'
      )}
    />
  );
}
