import React, { Fragment, useState, useRef } from 'react';
import Heading from '../components/ui/Heading';
import LoadingSpinner from '../components/ui/LoadingSpinner';
import NotificationMessage from '../components/NotificationMessage';
import IdentityVerificationForm from '../components/identity-verification/IdentityVerificationForm';
import { fetchVerifyCustomer, getToken, ApiValidationError } from '../api';
import { Redirect } from 'react-router';
import pickBy from 'lodash/pickBy';
import { useMount } from 'react-use';
import { scrollToElement } from '../utils';
import styled from 'styled-components';
import ContentBackground from '../components/ContentBackground';
import BodyContent from '../components/BodyContent';

const StyledHeading = styled(Heading)`
  color: #fff;
  text-align: center;
  margin-top: 1rem;
  margin-bottom: 0.25rem;
`
const initialFormData = {
  dob_day: '',
  dob_month: '',
  dob_year: '',
  address: '',
  street_2: '',
  city: '',
  country: '',
  state: '',
  zip: '',
  ssn: '',
  first_name: '',
  last_name: '',
  phone: ''
};

export default function IdentityVerify({
  config,
  location,
  history,
  onLogOutClick
}) {
  const [data, setData] = useState({
    ...initialFormData,
    ...(config.instance &&
      config.instance.identity && {
        first_name: config.instance.identity.first_name,
        last_name: config.instance.identity.last_name,
        address: config.instance.identity.address,
        address2: config.instance.identity.address2,
        city: config.instance.identity.city,
        country: config.instance.identity.country,
        state: config.instance.identity.state,
        zip: config.instance.identity.zip,
        dob_day: config.instance.identity.dob_day,
        dob_month: config.instance.identity.dob_month,
        dob_year: config.instance.identity.dob_year,
        phone: config.instance.identity.phone
      })
  });
  const [validation, setValidation] = useState(null);
  const [loading, setLoading] = useState(
    location.state && location.state.shouldResendCode
  );
  const topRef = useRef();

  useMount(() => {
    scrollToElement(topRef);
  });

  const identity =
    (location.state && location.state.identity) || config.instance.identity;

  if (identity === "false") {
    return <Redirect to="/indentity/failedtoverify" noThrow />;
  }

  async function handleSubmit(formData) {
    try {
      setLoading(true);
      scrollToElement(topRef);

      // transform into the format that fetchVerifyCustomer expects
      const transformedData = {
        dob: [
          formData.dob_year,
          String(formData.dob_month).padStart(2, '0'),
          String(formData.dob_day).padStart(2, '0')
        ].join('-'),
        dob_day: formData.dob_day,
        dob_month: formData.dob_month,
        dob_year: formData.dob_year,
        address: formData.address,
        address2: formData.street_2,
        city: formData.city,
        country: formData.country,
        state: formData.state,
        zip: formData.zip
          ? formData.zip.replace(/[^0-9]/g, '').substr(0, 5)
          : '',
        ssn: formData.ssn,
        first_name: formData.first_name,
        last_name: formData.last_name,
        phone: formData.phone
      };

      const response = await fetchVerifyCustomer(
        await getToken(config.clientId),
        transformedData
      );
      const three_tl_referral = !!sessionStorage['three_tl_referral']; // set based on session storage
      const three_tl_return_page = sessionStorage['three_tl_return_page'];

      if (response.data.verification.verified) {
        history.push('/identity/complete', {
          identity: { ...identity, ...transformedData },
          title: 'Verification Complete!',
          flow_was_completed: true,
          message: {
              type: 'success',
              text: "You've been successfully verified",
              show3tlLink: three_tl_referral,
              returnPage: three_tl_return_page
          }
      });
        } else if (response.data.hasOwnProperty('kba')) {
        history.push('/identity/KBA', {
          identity: { ...identity, ...transformedData },
          kba: response.data.kba
        });
      } else {
        history.push('/identity/failedtoverify')
      }
    } catch (error) {
      if (error instanceof ApiValidationError) {
        setValidation(error.validationErrors);
        scrollToElement(topRef);
      } else {
        throw error;
      }
    } finally {
      setLoading(false);
    }
  }

  function handleFieldChange(name) {
    if (['dob_day', 'dob_month', 'dob_year'].includes(name)) {
      // Special logic for handling the date fields. They get combined into the `dob` field which is what gets the errors.
      setValidation(pickBy(validation, (v, k) => k !== 'dob'));
    } else {
      // Clear validation messages for the field that changed.
      setValidation(pickBy(validation, (v, k) => k !== name));
    }
  }


  return (
    <div style={{height: "100%"}} ref={topRef}>
      <StyledHeading h1>{config.customerVerify.lang.identityVerifyTitle}</StyledHeading>

      {loading ? (
        <LoadingSpinner message="Verifying your details..." />
      ) : (
        <Fragment>
          {!!location.state && !!location.state.message && !validation && (
            <NotificationMessage message={location.state.message} />
          )}
          <BodyContent>{config.customerVerify.lang.identityVerifyBody}</BodyContent>
          <ContentBackground>
            <IdentityVerificationForm
              data={data}
              validation={validation || {}}
              onDataChange={data => setData(data)}
              onFieldChange={handleFieldChange}
              onSubmit={handleSubmit}
              onLogOutClick={onLogOutClick}
            />
          </ContentBackground>
        </Fragment>
      )}
    </div>
  );
}
