import React from 'react';
import { css, cx } from 'emotion';
import { id } from '../../utils';

export default function Button({
  className,
  block = false,
  link = false,
  alt = false,
  ...props
}) {
  return (
    <button
      className={cx(
        id('button'),
        css`
          box-sizing: border-box;
          display: inline-flex;
          align-items: center;
          justify-content: center;
        `,
        block &&
          css`
            display: flex;
            width: 100%;
          `,
        link && id('button-link button btn-alt'),
        alt && 'btn-alt',
        className,
        !link && 'button',
        !link && 'button--primary'
      )}
      {...props}
    />
  );
}
