import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from '@emotion/styled';
import { id } from '../../utils';
import FormGroup from '../ui/FormGroup';
import Grid from 'hedron';
import { FaRedoAlt } from 'react-icons/fa';

const UPLOAD_MAX_SIZE = 5 * 1024 * 1024;

const IdentityUploadContainer = styled('div')`
  display: flex;
  flex-direction: vertical;
  min-height: 200px;
`;

IdentityUploadContainer.defaultProps = {
  className: id('identity-upload-container')
};

const ImageLabel = styled('small')`
  display: inline-block;
`;

const IdentityUploadButton = styled('div')`
  display: inline-flex;
  align-items: center;
  justify-content: center;
`;

IdentityUploadButton.defaultProps = {
  className: id('identity-upload-button')
};

const UploadedImage = styled('div')`
  display: block;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: contain;
  height: 100%;
  width: 100%;
  flex-shrink: 0;
  flex-grow: 1;
  ${({ src }) => `
		background-image: url("${src}");
	`}
`;

UploadedImage.defaultProps = {
  className: id('identity-upload-image')
};

const ReuploadBar = styled('a')`
  background: black;
  color: white;
  display: block;
  cursor: pointer;
  padding: 0.5rem;
  text-align: center;
`;

ReuploadBar.defaultProps = {
  className: id('identity-upload-reupload')
};

export default function IdentityUpload({
  name,
  label,
  icon: Icon,
  onFileSelected,
  onFileRejected,
  currentFile,
  validation
}) {
  const onDrop = useCallback(
    acceptedFiles => {
      if (acceptedFiles.length) {
        onFileSelected(acceptedFiles[0]);
      }
    },
    [onFileSelected]
  );

  const onDropRejected = useCallback(
    rejectedFiles => {
      const [rejectedFile] = rejectedFiles;

      if (rejectedFile.size > UPLOAD_MAX_SIZE) {
        onFileRejected(rejectedFile, 'The image must be a maximum of 5MB');
      } else if (
        !rejectedFile.name.match(/\.(jpg|jpeg|png)$/) ||
        !['image/jpeg', 'image/png'].includes(rejectedFile.type)
      ) {
        onFileRejected(rejectedFile, 'The image must be a JPEG or PNG file');
      } else {
        onFileRejected(rejectedFile, 'Image is not valid');
      }
    },
    [onFileRejected]
  );

  const [imageData, setImageData] = useState(null);

  useEffect(() => {
    if (currentFile) {
      const reader = new FileReader();
      reader.onload = evt => {
        // Attempt to load the image.
        const img = new Image();
        img.onload = () => {
          setImageData(evt.target.result);
        };
        img.onerror = () => {
          onFileRejected(
            currentFile,
            'The image you selected is not a valid JPEG or PNG file'
          );
          setImageData(null);
        };

        img.src = evt.target.result;
      };
      reader.readAsDataURL(currentFile);
    } else {
      setImageData(null);
    }
  }, [currentFile, onFileRejected]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    onDropRejected,
    accept: ['.jpg', '.jpeg', '.png'],
    multiple: false,
    maxSize: UPLOAD_MAX_SIZE
  });

  if (currentFile && imageData) {
    return (
      <IdentityUploadContainer>
        <Grid.Provider breakpoints={{ sm: '+0' }} padding="0px">
          <Grid.Bounds direction="vertical" sm={{ width: '100%' }}>
            <Grid.Box
              sm={{ width: '100%', padding: '0.5rem', height: '100%' }}
              fill
              style={{ display: 'flex' }}
            >
              {imageData && <UploadedImage src={imageData} />}
            </Grid.Box>
            <Grid.Box sm={{ width: '100%' }}>
              <ReuploadBar onClick={() => onFileSelected(null)}>
                <FaRedoAlt /> Re-upload
              </ReuploadBar>
            </Grid.Box>
          </Grid.Bounds>
        </Grid.Provider>
      </IdentityUploadContainer>
    );
  }

  return (
    <IdentityUploadContainer {...getRootProps()} active={isDragActive}>
      <Grid.Provider breakpoints={{ sm: '+0' }} padding="10px">
        <Grid.Bounds direction="vertical" valign="center" halign="center">
          <Grid.Box>
            <Icon size="96px" />
          </Grid.Box>
          <Grid.Box>
            <FormGroup validation={validation}>
              <p>Drag image of the {label}, or</p>
              <IdentityUploadButton>
                Choose File
                <input {...getInputProps()} name={name} id={id(name)} />
              </IdentityUploadButton>
              {currentFile && <ImageLabel>{currentFile.name}</ImageLabel>}
              <p>.png or .jpg files only, max 5mb each</p>
            </FormGroup>
          </Grid.Box>
        </Grid.Bounds>
      </Grid.Provider>
    </IdentityUploadContainer>
  );
}
