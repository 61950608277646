import React, { Fragment } from 'react';
import { id } from '../../utils';
import PinEntry from '../ui/PinEntry';
import Button from '../ui/Button';
import FormGroup from '../ui/FormGroup';
import Section from '../ui/Section';

import ButtonActionGroup from '../ui/ButtonActionGroup';
import Label from '../ui/Label';
import { Link } from 'react-router-dom';

export default function PhoneVerificationForm({
  data,
  validation,
  config,
  onDataChange,
  onFieldChange,
  onSubmit,
  onBack,
  onLogOutClick
}) {
  function handleSubmit(evt) {
    evt.preventDefault();

    onSubmit(data);
  }

  function handleChange(evt) {
    onDataChange({ ...data, [evt.target.name]: evt.target.value });
    onFieldChange(evt.target.name, evt.target.value);
  }

  return (
    <Fragment>
      <form className={id('form-phone-verify-continue')}>
        <Section>
          <FormGroup validation={validation.code}>
            <Label htmlFor={id('code')}>Please enter the code here:</Label>
            <PinEntry
              id={id('code')}
              name="code"
              value={data.code}
              onChange={handleChange}
              maxLength={6}
              block
            />
          </FormGroup>
        </Section>

        {config.customerVerify.lang.phoneContinueBodyLower && (
          <p>{config.customerVerify.lang.phoneContinueBodyLower}</p>
        )}

        <p>
          If you do not receive a code within two minutes, please try again.
        </p>

        <ButtonActionGroup>
          <Button type="submit" onClick={handleSubmit}>
            Verify Phone
          </Button>

          <Button
            alt
            className="button button-primary" 
            onClick={e => {
              e.preventDefault();
              onBack();
            }}
          >
            Resend Code
          </Button>
        </ButtonActionGroup>
        <ButtonActionGroup>
          <Link className="button button-primary"  to="/login.php?action=logout" onClick={onLogOutClick}>
            Log out
          </Link>
        </ButtonActionGroup>
      </form>
    </Fragment>
  );
}
