import styled from '@emotion/styled';
import { id } from '../../utils';

// export default styled('div')`
//   background: #f0f5ff;
//   padding: 1rem;
//   border-radius: 0.5rem;
//   border: 1px solid #e0e2f0;
// `;

const Section = styled('div')``;

Section.defaultProps = {
  className: id('section')
};

export default Section;
