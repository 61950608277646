import React, { useState } from 'react';
import Popover from 'react-popover';
import { MdInfoOutline } from 'react-icons/md';
import styled from '@emotion/styled';
import { css } from 'emotion';

import { id } from '../../utils';

const PopoverContent = styled.div`
  background: #d8d8d8;
  max-width: 250px;
  padding: 1rem;
  font-size: 85%;
  font-style: italic;
  line-height: 1;
`;

export default function FieldInfoPopover({ content }) {
  const [isOpen, setIsOpen] = useState(false);

  function handleClick(e) {
    e.preventDefault();
    setIsOpen(true);
  }

  function handleClickOutside(e) {
    e.preventDefault();
    setIsOpen(false);
  }

  return (
      <Popover
        preferPlace="right"
        place="right"
        body={<PopoverContent>{content}</PopoverContent>}
        isOpen={isOpen}
        onOuterAction={handleClickOutside}
        tipSize={10}
        appendTarget={document.body}
        className={
          (id('popover'),
          css`
            polygon.Popover-tipShape {
              fill: #d8d8d8;
            }
          `)
        }
      >
        <MdInfoOutline
          onClick={handleClick}
          onMouseOver={handleClick}
          onMouseOut={handleClickOutside}
        />
      </Popover>
  );
}
