import React, { useRef } from 'react';
import Heading from '../components/ui/Heading';
import NotificationMessage from '../components/NotificationMessage';
// import { Redirect } from 'react-router';
import Button from '../components/ui/Button';
import ButtonActionGroup from '../components/ui/ButtonActionGroup';
import { Link } from 'react-router-dom';
import { useMount } from 'react-use';
import { scrollToElement } from '../utils';
import styled from 'styled-components';


const StyledHeading = styled(Heading)`
color: #fff;
text-align: center;
`


export default function IdentityComplete({
  config,
  location,
  history,
  onLogOutClick
}) {
  const topRef = useRef();

  useMount(() => {
    scrollToElement(topRef);
  });

  // if (!location.state.identity) {
  //   return <Redirect to="/phone/verify" noThrow />;
  // }

  function handleCloseClick() {
    config.instance.onFlowComplete(
      location.state.flow_was_completed === undefined
        ? true
        : location.state.flow_was_completed,
      location.state.identity
    );
  }

  return (
    <div style={{height: "100vh"}} ref={topRef}>
      <StyledHeading h1>{location.state.title}</StyledHeading>
      {!!location.state.message && (
        <NotificationMessage style={{color: "#fff"}} message={location.state.message} />
      )}

      <ButtonActionGroup>
        <Button className="button button-primary" type="submit" onClick={handleCloseClick}>
          Close
        </Button>
      </ButtonActionGroup>
      <ButtonActionGroup>
        <Link className="button button-primary" to="/login.php?action=logout" onClick={onLogOutClick}>
          Log out
        </Link>
      </ButtonActionGroup>
    </div>
  );
}
