import React from 'react';
import styled from "styled-components";

const StyledContentBackground = styled.div`
  background: rgba(9, 0, 0, 0.18);
//   margin-bottom: 10rem;
  height: auto; 
  padding-bottom: 6rem;
  display: flex;
  justify-content: center;
  

  @media (min-width: 650px) {
      padding: 2rem;
  }

  @media (min-width: 1440px) {
      padding: 16px 10rem 0;
      padding-bottom: 6rem;
  }
`

const ContentBackground = ({ children }) => (
    <StyledContentBackground className="content-background">
        {children}
    </StyledContentBackground>
)

export default ContentBackground;