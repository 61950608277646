import { FaPassport, FaIdCard } from 'react-icons/fa';

const identityTypes = [
  {
    name: 'passport',
    label: 'Passport',
    icon: FaPassport,
    instructions: 'ID page of your passport'
  },
  {
    name: 'license',
    label: "Driver's License",
    icon: FaIdCard,
    instructions: 'front of your license'
  },
  {
    name: 'license',
    label: 'Identity Document',
    icon: FaIdCard,
    instructions: 'front of your ID'
  }
];

export default identityTypes;
