import React, { Fragment, useState, useRef } from 'react';
import Heading from '../components/ui/Heading';
import LoadingSpinner from '../components/ui/LoadingSpinner';
import NotificationMessage from '../components/NotificationMessage';
import { fetchUploadIdentification, getToken } from '../api';
// import { Redirect } from 'react-router';
import IdentityUploadForm from '../components/identity-upload/IdentityUploadForm';
import IdentityTypeSelector from '../components/identity-upload/IdentityTypeSelector';
import pick from 'lodash/pick';
import { useMount } from 'react-use';
import { scrollToElement } from '../utils';

const initialFormData = {
  front: null
};

export default function IdentityUpload({
  config,
  location,
  history,
  onLogOutClick
}) {
  const [data, setData] = useState({ ...initialFormData });
  const [validation, setValidation] = useState({});
  const [idType, setIdType] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const topRef = useRef();

  useMount(() => {
    scrollToElement(topRef);
  });

  // if (!location.state.identity) {
  //   return <Redirect to="/phone/verify" noThrow />;
  // }

  async function handleSubmit() {
    // We don't want to rely entirely on the server-side validation here
    // because it means uploading large images.
    // Check that we actually have images before continuing...
    const validation = {};
    let didFailValidation = false;

    if (!data.front) {
      validation.front = [
        'You must upload an image of the front side of your identity card'
      ];
      didFailValidation = true;
    }

    if (didFailValidation) {
      setValidation(validation);
      scrollToElement(topRef);
      return;
    } else {
      try {
        setLoading(true);

        const response = await fetchUploadIdentification(
          await getToken(config.clientId),
          idType,
          data.front,
          pick(location.state.identity, [
            'first_name',
            'last_name',
            'address',
            'city',
            'state',
            'zip',
            'phone',
            'dob',
            'ssn',
            'country'
          ])
        );
        if (response.data.verification.verified) {
          config.instance.onFlowComplete(true, location.state.identity);
        } else {
          if (response.data.verification['file-uploaded']) {
            history.push('/identity/complete', {
              ...location.state,
              title: config.customerVerify.lang.identityCompleteUploadedTitle,
              flow_was_completed: false,
              message: {
                text: config.customerVerify.lang.identityCompleteUploadedBody,
                type: 'error'
              }
            });
          } else {
            history.push('/identity/complete', {
              ...location.state,
              title: config.customerVerify.lang.identityCompleteFailedTitle,
              flow_was_completed: false,
              message: {
                text: config.customerVerify.lang.identityCompleteFailedBody,
                type: 'error'
              }
            });
          }
        }
      } catch (e) {
        setErrorMessage({ type: 'error', text: String(e) });
        scrollToElement(topRef);
      } finally {
        setLoading(false);
      }
    }
  }

  function handleFileRejected(file, reason) {
    setValidation({
      front: [reason]
    });

    setData({
      front: null
    });
  }

  function handleFileSelected(file) {
    setValidation({});
  }

  function handleUploadBackClick() {
    setData({ front: null });
    setIdType(null);
  }

  return (
    <div ref={topRef}>
      <Heading h1>{config.customerVerify.lang.identityUploadTitle}</Heading>

      {loading ? (
        <LoadingSpinner message="Uploading your image..." />
      ) : (
        <Fragment>
          {(!!errorMessage || !!location.state.message) && (
            <NotificationMessage
              message={errorMessage || location.state.message}
            />
          )}

          <p>{config.customerVerify.lang.identityUploadBody}</p>

          {idType ? (
            <IdentityUploadForm
              data={data}
              validation={validation}
              onDataChange={data => setData(data)}
              onSubmit={handleSubmit}
              onBack={handleUploadBackClick}
              identityType={idType}
              onLogOutClick={onLogOutClick}
              onFileRejected={handleFileRejected}
              onFileSelected={handleFileSelected}
            />
          ) : (
            <IdentityTypeSelector onChange={setIdType} />
          )}
        </Fragment>
      )}
    </div>
  );
}
