import React from 'react';
import { BounceLoader } from 'react-spinners';
import styled from '@emotion/styled';

const LoadingContainer = styled('div')`
  text-align: center;
  width: 100%;
`;

export default function LoadingSpinner({ message }) {
  return (
    <LoadingContainer>
      <BounceLoader
        size={48}
        css={`
          margin: 0 auto;
          display: block;
        `}
      />
      <p style={{color: "#fff"}}>{message}</p>
    </LoadingContainer>
  );
}
