import React, { Fragment } from 'react';
import identityTypes from '../../identity-types';
import { css, cx } from 'emotion';
import { id } from '../../utils';

function IdentityTypeButton({ className, ...props }) {
  return (
    <button
      className={cx(
        css`
          display: flex;
          align-items: center;
          justify-content: flex-start;
          display: flex;
          width: 100%;

          > svg {
            margin-right: 1rem;
          }
        `,
        className,
        id('identity-type-button')
      )}
      {...props}
    />
  );
}

function IdentityType({ label, icon: Icon, onClick }) {
  return (
    <IdentityTypeButton block onClick={onClick}>
      <Icon size="96px" />
      {label}
    </IdentityTypeButton>
  );
}

export default function IdentityTypeSelector({ onChange }) {
  return (
    <Fragment>
      {identityTypes.map(identityType => (
        <IdentityType
          label={identityType.label}
          icon={identityType.icon}
          onClick={() => onChange(identityType)}
        />
      ))}
    </Fragment>
  );
}
