import jwt from 'jsonwebtoken';
import { STORE_HASH, CUSTOMER_EMAIL } from './globals';

export const API_ROOT =
  process.env.REACT_APP_VERATAD_API_ROOT ||
  `https://leap-veratad.intuitsolutions-apps.net`;
export const CURRENT_CUSTOMER_ENDPOINT = '/customer/current.jwt';
export const CURRENT_CUSTOMER_ENDPOINT_DEV = `${API_ROOT}/app/configuration/jwt`;

export class ApiError extends Error {}
export class ApiValidationError extends ApiError {
  constructor(message, validationErrors) {
    super(message);
    this.validationErrors = validationErrors;
  }
}

export function throwIfError(data) {
  if (!data || !data.meta || data.meta.status === 'error') {
    const errorMessage =
      (data && data.meta && data.meta.message) ||
      'An unexpected error occurred';

    const isValidationError = data && data.data && data.data.validation;

    if (isValidationError) {
      throw new ApiValidationError(
        errorMessage,
        data.data.validation.reduce((acc, validationResult) => {
          acc[validationResult.field] = validationResult.errors;

          return acc;
        }, {})
      );
    }

    throw new ApiError(errorMessage);
  }

  return data;
}

function fetchWithParams(url, params, opts = {}) {
  const usp = new URLSearchParams();

  for (let [k, v] of Object.entries(params)) {
    usp.set(k, v);
  }

  if (!opts.credentials) {
    opts.credentials = 'same-origin';
  }

  return fetch(`${url}?${usp.toString()}`, opts);
}

export function fetchConfiguration(storeHash) {
  return fetchWithParams(`${API_ROOT}/app/configuration`, { store: storeHash })
    .then(res => res.json())
    .then(throwIfError);
}

export function parseCustomerToken(token) {
  const decoded = jwt.decode(token);

  return { ...decoded, token };
}

export async function fetchCustomerToken(clientId) {
  const response =
    process.env.NODE_ENV === 'development'
      ? await fetchWithParams(CURRENT_CUSTOMER_ENDPOINT_DEV, {
          app_client_id: clientId,
          store_hash: STORE_HASH,
          customer_email: CUSTOMER_EMAIL
        })
      : await fetchWithParams(CURRENT_CUSTOMER_ENDPOINT, {
          app_client_id: clientId
        });

  if (!response.ok) {
    throw new Error('Unable to fetch customer token - are you logged in?');
  }

  const token = await response.text();

  return parseCustomerToken(token);
}

export async function refreshTokenIfNecessary(clientId, token = null) {
  if (!token || token.exp <= Math.floor(Date.now() / 1000) + 15) {
    return await fetchCustomerToken(clientId);
  }

  return token;
}

export function fetchVerifyPhone(method, customerToken, phone) {
  return fetch(`${API_ROOT}/app/phone_verify`, {
    method: 'POST',
    mode: 'cors',
    body: JSON.stringify({
      phone,
      method,
      customer_token: customerToken.token
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(res => res.json())
    .then(throwIfError);
}

export function fetchContinuePhone(
  customerToken,
  continuationToken,
  code,
  flow
) {
  return fetch(`${API_ROOT}/app/phone_verify/code`, {
    method: 'POST',
    body: JSON.stringify({
      customer_token: customerToken.token,
      token: continuationToken,
      code,
      flow
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(res => res.json())
    .then(throwIfError);
}

export function fetchVerifyCustomer(
  customerToken,
  { dob, address, city, country, state, zip, ssn, first_name, last_name, phone }
) {
  return fetch(`${API_ROOT}/app/customer_verify`, {
    method: 'POST',
    body: JSON.stringify({
      customer_token: customerToken.token,
      dob,
      address,
      city,
      country,
      state,
      zip,
      ssn,
      first_name,
      last_name,
      phone
    }),
    headers: {
      'Content-Type': 'application/json'
    }
  })
    .then(res => res.json())
    .then(throwIfError);
}

let currentToken;

export async function getToken(clientId) {
  currentToken = await refreshTokenIfNecessary(clientId, currentToken);

  return currentToken;
}

export function fetchUploadIdentification(
  customerToken,
  identityType,
  front,
  { first_name, last_name, address, city, state, zip, phone, dob, ssn, country }
) {
  const formData = new FormData();
  formData.set('customer_token', customerToken.token);
  formData.set('front', front);
  formData.set('id_type', identityType.name);
  formData.set('first_name', first_name);
  formData.set('last_name', last_name);
  formData.set('address', address);
  formData.set('city', city);
  formData.set('state', state);
  formData.set('zip', zip);
  formData.set('phone', phone);
  formData.set('dob', dob);
  formData.set('ssn', ssn);
  formData.set('country', country);

  return fetch(`${API_ROOT}/app/customer_verify/upload`, {
    method: 'POST',
    body: formData
  })
    .then(res => res.json())
    .then(throwIfError);
}

export function fetchIsVerificationRequired(
  customerToken,
  customerName,
  phoneNumber
) {
  const params = new URLSearchParams();
  params.set('customer_token', customerToken.token);
  params.set('name', customerName);
  params.set('phone', phoneNumber);

  return fetch(
    `${API_ROOT}/app/customer_verify/verification_required?${params.toString()}`,
    {
      method: 'GET'
    }
  )
    .then(res => res.json())
    .then(throwIfError);
}

export function fetchGetVerificationStatus(customerToken) {
  const params = new URLSearchParams();
  params.set('customer_token', customerToken.token);

  return fetch(
    `${API_ROOT}/app/customer_verify/verification_status?${params.toString()}`,
    {
      method: 'GET'
    }
  )
    .then(res => res.json())
    .then(throwIfError);
}

export function fetchGetCustomerPrefill(customerToken) {
  const params = new URLSearchParams();
  params.set('customer_token', customerToken.token);

  return fetch(
    `${API_ROOT}/app/customer_verify/get_customer_prefill?${params.toString()}`,
    {
      method: 'GET'
    }
  )
    .then(res => res.json())
    .then(throwIfError);
}

export function fetchUpdateCustomerPhoneNumber(customerToken, phoneNumber) {
  const params = new FormData();
  params.append('customer_token', customerToken.token);
  params.append('phone', phoneNumber);

  return fetch(`${API_ROOT}/app/customer_verify/update_phone_number`, {
    method: 'POST',
    body: params
  })
    .then(res => res.json())
    .then(throwIfError);
}

export function fetchUpdateCustomerAccountName(
  customerToken,
  firstName,
  lastName
) {
  const params = new FormData();
  params.append('customer_token', customerToken.token);
  params.append('first_name', firstName);
  params.append('last_name', lastName);

  return fetch(`${API_ROOT}/app/customer_verify/update_account_name`, {
    method: 'POST',
    body: params
  })
    .then(res => res.json())
    .then(throwIfError);
}
