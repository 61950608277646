import React from 'react';
import ConfigurationContext from './context/ConfigurationContext';
import App from './App';
import { fetchConfiguration } from './api';
import { STORE_HASH } from './globals';

export default class AppContainer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      configuration: { ready: false, error: false, data: null }
    };
  }

  async componentDidMount() {
    try {
      const { data } = await fetchConfiguration(STORE_HASH);

      this.setState({
        configuration: { ready: true, error: false, data: data.configuration }
      });
    } catch (error) {
      console.error(
        'Unable to initialize customer verification: error fetching configuration'
      );
      console.error(error);

      this.setState({
        configuration: { ready: true, error: true, data: null }
      });
    }
  }

  render() {
    const { configuration } = this.state;
    const { flow, onFlowComplete, onPhoneVerified, identity } = this.props;

    if (configuration.error) {
      return null;
    } else if (configuration.ready) {
      return (
        <ConfigurationContext.Provider
          value={{
            ...configuration.data,
            instance: {
              flow,
              onFlowComplete,
              onPhoneVerified,
              identity
            }
          }}
        >
          <App />
        </ConfigurationContext.Provider>
      );
    } else {
      return null;
    }
  }
}
