import React, { Fragment, useState, useRef } from 'react';
import PhoneContinueForm from '../components/phone-verification/PhoneContinueForm';
import Heading from '../components/ui/Heading';
import LoadingSpinner from '../components/ui/LoadingSpinner';
import NotificationMessage from '../components/NotificationMessage';
import { getToken, ApiValidationError, fetchContinuePhone } from '../api';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import Button from '../components/ui/Button';
import ButtonActionGroup from '../components/ui/ButtonActionGroup';
import pickBy from 'lodash/pickBy';
import { scrollToElement } from '../utils';

const initialFormData = {
  code: ''
};

export default function PhoneContinue({
  match: {
    params: { continuationToken }
  },
  config,
  location,
  history,
  onLogOutClick
}) {
  const [data, setData] = useState(initialFormData);
  const [validation, setValidation] = useState({});
  const [loading, setLoading] = useState(false);
  const [codeFailed, setCodeFailed] = useState(false);
  const topRef = useRef();

  if (!location.state.identity) {
    return <Redirect to="/phone/verify" noThrow />;
  }

  async function handleSubmit(formData) {
    try {
      setLoading(true);
      scrollToElement(topRef);

      const response = await fetchContinuePhone(
        await getToken(config.clientId),
        continuationToken,
        formData.code,
        config.instance.flow
      );

      if (!response.data.verified) {
        setCodeFailed(true);
      } else {
        config.instance.onPhoneVerified({ ...location.state.identity }, true);

        if (config.instance.flow === 'all') {
          history.push('/identity/verify', {
            ...location.state,
            message: {
              type: 'success',
              text: 'Your phone number was successfully verified!'
            }
          });
        } else {
          config.instance.onFlowComplete(true, location.state.identity);
        }
      }
    } catch (error) {
      if (error instanceof ApiValidationError) {
        setValidation(error.validationErrors);
      } else {
        throw error;
      }
    } finally {
      setLoading(false);
    }
  }

  function handleBack() {
    history.push('/phone/verify', {
      ...location.state,
      shouldResendCode: true
    });
  }

  function handleFieldChange(name) {
    // Clear validation messages for the field that changed.
    setValidation(pickBy(validation, (v, k) => k !== name));
  }

  return (
    <div ref={topRef}>
      <Heading h1>{config.customerVerify.lang.phoneContinueTitle}</Heading>

      {loading ? (
        <LoadingSpinner message="Verifying code..." />
      ) : (
        <Fragment>
          {!!location.state && !!location.state.message && !codeFailed && (
            <NotificationMessage message={location.state.message} />
          )}

          <p>{config.customerVerify.lang.phoneContinueBody}</p>

          {codeFailed ? (
            <Fragment>
              <NotificationMessage
                message={{
                  type: 'error',
                  text: (
                    <Fragment>
                      We were unable to verify your phone number. Please try
                      again.
                    </Fragment>
                  )
                }}
              />
              <ButtonActionGroup>
                <Button
                className="button button-primary" 
                  alt
                  onClick={e => {
                    e.preventDefault();
                    handleBack();
                  }}
                >
                  Resend Code
                </Button>
              </ButtonActionGroup>
              <ButtonActionGroup>
                <Link className="button button-primary" to="/login.php?action=logout" onClick={onLogOutClick}>
                  Log out
                </Link>
              </ButtonActionGroup>
            </Fragment>
          ) : (
            <PhoneContinueForm
              data={data}
              validation={validation}
              onDataChange={data => setData(data)}
              onFieldChange={handleFieldChange}
              onSubmit={handleSubmit}
              onBack={handleBack}
              onLogOutClick={onLogOutClick}
              config={config}
            />
          )}
        </Fragment>
      )}
    </div>
  );
}
