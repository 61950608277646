import styled from '@emotion/styled';
import { cx } from 'emotion';
import { id } from '../../utils';

const ButtonActionGroup = styled('div')`
  margin-top: 1rem;

  display: flex;
  align-items: ${({ align }) => align || 'baseline'};
  justify-content: ${({ justify }) => justify || 'center'};

  > button {
    margin-right: 0.5rem;
    &:last-child {
      margin-right: 0;
    }
  }
`;

ButtonActionGroup.defaultProps = {
  className: cx(id('button-action-group'))
};

export default ButtonActionGroup;
