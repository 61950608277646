import React from 'react';
import Select from './Select';
import styled from 'styled-components';

// When we support multiple countries...

// import countries from '../../data/countries';

// const sortedCountries = Object.entries(countries.data)
//   .sort((a, b) => (a[1] < b[1] ? -1 : +(a[1] > b[1])))
//   .reduce((acc, [k, v]) => {
//     acc[k] = v;

//     return acc;
// }, {});

// USA only

const StyledSelect = styled(Select)`
  background: transparent;
  color: #fff;
  border: 1px solid white;
  :focus {
  border: 2px solid white;
  }
`

const sortedCountries = {'USA': 'United States of America'};

export default function CountrySelect(props) {
  return (
    <StyledSelect {...props}>
      <option value="">Select...</option>
      {Object.entries(sortedCountries).map(([code, name], i) => (
        <option key={i} value={code}>
          {name}
        </option>
      ))}
    </StyledSelect>
  );
}
