import { API_ROOT } from './api';

export function id(str) {
  return `its-vt-${str}`;
}

export function createRoot() {
  const existingRoot = document.querySelector('#' + id('root'));

  if (existingRoot) {
    return existingRoot;
  }

  const root = document.createElement('div');
  root.id = id('root');
  document.body.appendChild(root);
  return root;
}

export function addStylesheet() {
  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.href = `${API_ROOT}/assets/storefront-2fa/static/css/veratad-2fa.min.css`;
  document.head.appendChild(link);
}

export function scrollToElement(elementRef, offset = -400) {
  if (elementRef.current) {
    window.scrollTo({
      top:
        elementRef.current.getBoundingClientRect().top +
        window.pageYOffset +
        offset,
      behavior: 'auto'
    });
  } else {
    console.log('Cannot scroll to ref, it is unset');
  }
}
