import React from 'react';
import pickBy from 'lodash/pickBy';
import { cx } from 'emotion';
import { id } from '../../utils';

const headingLevels = ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'];

export default function Heading({ className, ...props }) {
  for (const level of headingLevels) {
    if (props[level]) {
      return React.createElement(level, {
        ...pickBy(props, (value, key) => !headingLevels.includes(key)),
        className: cx(id('heading'), id(`heading-${level}`), className)
      });
    }
  }

  return React.createElement('h1', {
    ...pickBy(props, (value, key) => !headingLevels.includes(key)),
    className: cx(id('heading'), id('heading-h1'), className)
  });
}
