import React, { Fragment, useState, useRef } from 'react';
import { useMount } from 'react-use';
import PhoneVerificationForm from '../components/phone-verification/PhoneVerificationForm';
import Heading from '../components/ui/Heading';
import NotificationMessage from '../components/NotificationMessage';
import LoadingSpinner from '../components/ui/LoadingSpinner';
import pickBy from 'lodash/pickBy';

import {
  fetchVerifyPhone,
  getToken,
  ApiValidationError,
  ApiError
} from '../api';
import { scrollToElement } from '../utils';

const initialFormData = {
  phone: '',
  method: 'sms'
};

function censorNumber(phoneNumber) {
  const lastFour = phoneNumber
    .replace(/[^0-9]/g, '')
    .trim()
    .substr(-4);

  return '***-***-' + lastFour;
}

export default function PhoneVerify({
  config,
  location,
  history,
  onLogOutClick
}) {
  const [data, setData] = useState({
    ...initialFormData,
    ...(config.instance &&
      config.instance.identity && {
        phone: config.instance.identity.phone
      }),
    ...(location.state &&
      location.state.identity &&
      location.state.identity.phone && {
        phone: location.state.identity.phone,
        method: location.state.identity.method
      })
  });
  const [validation, setValidation] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const topRef = useRef();

  async function handleSubmit(formData) {
    try {
      setLoading(true);
      scrollToElement(topRef);

      let phone = formData.phone.replace(/[^\d]+/g, '');

      const response = await fetchVerifyPhone(
        formData.method,
        await getToken(config.clientId),
        phone
      );

      if (!response.data.token) {
        throw new Error('No token in response');
      }

      history.push(`/phone/continue/${response.data.token}`, {
        identity: {
          phone: formData.phone,
          method: formData.method
        },
        message: {
          type: 'success',
          text: `A verification was sent to the phone number listed in your account: ${censorNumber(
            formData.phone
          )}.`
        },
        shouldResendCode: false
      });
    } catch (error) {
      if (error instanceof ApiValidationError) {
        setValidation(error.validationErrors);
      } else if (error instanceof ApiError) {
        if (error.message === 'VOIP NOT ALLOWED') {
          setError('Sorry, we are unable to verify VOIP phone numbers.');
        } else {
          setError('We were unable to send a code to this number.');
        }
      } else {
        setError('We were unable to send a code to this number.');
      }

      scrollToElement(topRef);
    } finally {
      setLoading(false);
    }
  }

  useMount(() => {
    if (location.state && location.state.shouldResendCode) {
      handleSubmit(data);
    } else {
      scrollToElement(topRef);
    }
  });

  function handleFieldChange(name) {
    // Clear validation messages for the field that changed.
    setValidation(pickBy(validation, (v, k) => k !== name));
  }

  const topLang = config.instance.identity.phone
    ? config.customerVerify.lang.phoneVerifyBody
    : config.customerVerify.lang.phoneVerifyBodyNoPhone;

  return (
    <div ref={topRef}>
      <Heading h1>{config.customerVerify.lang.phoneVerifyTitle}</Heading>

      {loading ? (
        <LoadingSpinner message="Sending code..." />
      ) : (
        <Fragment>
          <p>{topLang || config.customerVerify.lang.phoneVerifyBody}</p>
          {!!location.state && !!location.state.message && !error && (
            <NotificationMessage message={location.state.message} />
          )}
          {error && (
            <NotificationMessage
              message={{
                type: 'error',
                text: <Fragment>{error}</Fragment>
              }}
            />
          )}
          <PhoneVerificationForm
            data={data}
            validation={validation}
            onDataChange={data => setData(data)}
            onFieldChange={handleFieldChange}
            onSubmit={handleSubmit}
            allowTest={config.customerVerify.allowTest2fa}
            onLogOutClick={onLogOutClick}
            config={config}
          />
        </Fragment>
      )}
    </div>
  );
}
