import React from 'react';
import styled from '@emotion/styled';
import { cx } from 'emotion';
import { id } from '../../utils';

const FormGroupInner = styled('div')`
  display: block;
  margin-bottom: 1rem;

  &:last-child {
    margin-bottom: 0;
  }

  ${({ validation }) =>
    Array.isArray(validation) && validation.length
      ? `
          input, select {
            border-color: #c33 !important;
          }
        `
      : ''}
`;

const ValidationError = styled('p')`
  font-size: 0.75rem;
  color: #c33;
`;

ValidationError.defaultProps = {
  className: id('validation-error')
};

export default function FormGroup({
  children,
  validation,
  className,
  ...props
}) {
  return (
    <FormGroupInner
      validation={validation}
      className={cx(id('form-group'), className, 'form-field')}
      {...props}
    >
      {children}

      {Array.isArray(validation) &&
        validation.length > 0 &&
        validation.map((error, i) => (
          <ValidationError key={i}>{error}</ValidationError>
        ))}
    </FormGroupInner>
  );
}
