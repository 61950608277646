import React, { Fragment } from 'react';
import { id } from '../../utils';
import Label from '../ui/Label';
import TextBox from '../ui/TextBox';
import Button from '../ui/Button';
import ButtonActionGroup from '../ui/ButtonActionGroup';
import CountrySelect from '../ui/CountrySelect';
import FormGroup from '../ui/FormGroup';
import Grid from 'hedron';
import MonthSelect from '../ui/MonthSelect';
import NumericRangeSelect from '../ui/NumericRangeSelect';
import Section from '../ui/Section';
import Heading from '../ui/Heading';
import StateSelect from '../ui/StateSelect';
import FieldInfoPopover from '../ui/FieldInfoPopover';
import { Link } from 'react-router-dom';

export default function IdentityVerificationForm({
  data,
  validation,
  onDataChange,
  onFieldChange,
  onSubmit,
  onLogOutClick
}) {
  function handleSubmit(evt) {
    evt.preventDefault();

    onSubmit(data);
  }

  function handleChange(evt) {
    onDataChange({ ...data, [evt.target.name]: evt.target.value });
    onFieldChange(evt.target.name, evt.target.value);
  }

  return (
    <Fragment>
        <form style={{marginBottom: "24px"}} className={id('form-age-verify')}>
        <Section>
          <Grid.Provider
            breakpoints={{ sm: '-800', md: '+801' }}
            padding="10px"
          >
            <Grid.Bounds
              direction="horizontal"
              sm={{ width: '100%' }}
              md={{ width: '100%' }}
              wrap={true}
            >
              <Grid.Box
                sm={{ width: '100%' }}
                md={{ width: '100%' }}
                className={id('flex-heading-personal-information')}
              >
                <Heading style={{color: "white"}} h2>Personal Information</Heading>
              </Grid.Box>
              <Grid.Box
                sm={{ width: '100%' }}
                md={{ width: '50%' }}
                className={id('flex-first-name')}
              >
                <FormGroup validation={validation.first_name}>
                  <Label htmlFor={id('first_name')} name="first_name">
                    First Name*:
                    <FieldInfoPopover content="Please enter your legal first and last name as it appears on your valid government issued ID, such as a passport. Nicknames are not accepted." />
                  </Label>

                  <TextBox
                    block
                    id={id('first_name')}
                    name="first_name"
                    value={data.first_name}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Grid.Box>

              <Grid.Box
                sm={{ width: '100%' }}
                md={{ width: '50%' }}
                className={id('flex-last-name')}
              >
                <FormGroup validation={validation.last_name}>
                  <Label htmlFor={id('last_name')} name="last_name">
                    Last Name*:
                  </Label>

                  <TextBox
                    block
                    id={id('last_name')}
                    name="last_name"
                    value={data.last_name}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Grid.Box>
              <Grid.Box
                sm={{ width: '100%' }}
                md={{ width: '100%' }}
                className={id('flex-dob')}
              >
                <FormGroup validation={validation.dob}>
                  <Label htmlFor={id('dob_day')}>
                    Date of Birth*:
                    <FieldInfoPopover content="Please enter your legal date of birth as it appears on your valid government issued ID." />
                  </Label>
                  <Grid.Bounds
                    sm={{ width: '100%' }}
                    md={{ width: '100%' }}
                    wrap={true}
                  >
                    <Grid.Box
                      sm={{ padding: '0 0 10px 0', width: '100%' }}
                      md={{ padding: '0px 20px 0 0', width: '34%' }}
                      fill
                      className={id('flex-dob-month')}
                    >
                      <MonthSelect
                        block
                        id={id('dob_month')}
                        name="dob_month"
                        value={data.dob_month}
                        onChange={handleChange}
                        defaultLabel="Month"
                      />
                    </Grid.Box>
                    <Grid.Box
                      sm={{ padding: '0 0 10px 0', width: '100%' }}
                      md={{ padding: '0px 20px 0 0', width: '33%' }}
                      fill
                      className={id('flex-dob-day')}
                    >
                      <NumericRangeSelect
                        block
                        id={id('dob_day')}
                        name="dob_day"
                        value={data.dob_day}
                        onChange={handleChange}
                        min={1}
                        max={31}
                        defaultLabel="Day"
                      />
                    </Grid.Box>
                    <Grid.Box
                      sm={{ padding: '0px', width: '100%' }}
                      md={{ padding: '0px', width: '33%' }}
                      fill
                      className={id('flex-dob-year')}
                    >
                      <NumericRangeSelect
                        block
                        id={id('dob_year')}
                        name="dob_year"
                        value={data.dob_year}
                        onChange={handleChange}
                        min={new Date().getFullYear() - 90}
                        max={new Date().getFullYear() - 21}
                        reverse
                        defaultLabel="Year"
                      />
                    </Grid.Box>
                  </Grid.Bounds>
                </FormGroup>
              </Grid.Box>
              <Grid.Box
                sm={{ width: '100%' }}
                md={{ width: '50%' }}
                className={id('flex-ssn')}
              >
                <FormGroup validation={validation.ssn}>
                  <Label htmlFor={id('ssn')} name="ssn">
                    Last four digits of SSN*:
                  </Label>

                  <TextBox
                    block
                    id={id('ssn')}
                    name="ssn"
                    value={data.ssn}
                    onChange={handleChange}
                    type="password"
                    maxLength={4}
                  />
                </FormGroup>
              </Grid.Box>
              <Grid.Box
                sm={{ width: '100%' }}
                md={{ width: '50%' }}
                className={id('flex-phone')}
              >
                <FormGroup validation={validation.phone}>
                  <Label htmlFor={id('phone')} name="phone">
                    Phone Number:
                  </Label>

                  <TextBox
                    block
                    id={id('phone')}
                    name="phone"
                    value={data.phone}
                    onChange={handleChange}
                    // type="password"
                    maxLength={10}
                  />
                </FormGroup>
              </Grid.Box>
              <Grid.Box
                sm={{ width: '100%' }}
                md={{ width: '100%' }}
                className={id('flex-heading-address')}
              >
                <Heading style={{color: "white"}} h2>Address</Heading>
              </Grid.Box>
              <Grid.Box
                sm={{ width: '100%' }}
                md={{ width: '100%' }}
                className={id('flex-address')}
              >
                <FormGroup validation={validation.address}>
                  <Label htmlFor={id('address')} name="address">
                    Billing Address Line 1*:
                    <FieldInfoPopover content="Please enter your current or former billing address." />
                  </Label>

                  <TextBox
                    block
                    id={id('address')}
                    name="address"
                    value={data.address}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Grid.Box>
              <Grid.Box
                sm={{ width: '100%' }}
                md={{ width: '100%' }}
                className={id('flex-address2')}
              >
                <FormGroup validation={validation.street_2}>
                  <Label htmlFor={id('street_2')} name="street_2">
                    Billing Address Line 2:
                  </Label>

                  <TextBox
                    block
                    id={id('street_2')}
                    name="street_2"
                    value={data.address2}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Grid.Box>

              <Grid.Box
                sm={{ width: '100%' }}
                md={{ width: '100%' }}
                className={id('flex-city')}
              >
                <FormGroup validation={validation.city}>
                  <Label htmlFor={id('city')} name="city">
                    City*:
                  </Label>

                  <TextBox
                    block
                    id={id('city')}
                    name="city"
                    value={data.city}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Grid.Box>

              <Grid.Box
                sm={{ width: '100%' }}
                md={{ width: '100%' }}
                className={id('flex-country')}
              >
                <FormGroup validation={validation.country}>
                  <Label htmlFor={id('country')} name="country">
                    Country*:
                  </Label>

                  <CountrySelect
                    block
                    id={id('country')}
                    name="country"
                    value={data.country}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Grid.Box>

              <Grid.Box
                sm={{ width: '100%' }}
                md={{ width: '50%' }}
                className={id('flex-state')}
              >
                <FormGroup validation={validation.state}>
                  <Label htmlFor={id('state')} name="state">
                    State*:
                  </Label>

                  {data.country === 'USA' ? (
                    <StateSelect
                      block
                      id={id('state-dropdown')}
                      name="state"
                      value={data.state}
                      onChange={handleChange}
                    />
                  ) : (
                    <TextBox
                      block
                      id={id('state')}
                      name="state"
                      value={data.state}
                      onChange={handleChange}
                    />
                  )}
                </FormGroup>
              </Grid.Box>
              <Grid.Box
                sm={{ width: '100%' }}
                md={{ width: '50%' }}
                className={id('flex-zip')}
              >
                <FormGroup validation={validation.zip}>
                  <Label htmlFor={id('zip')} name="zip">
                    Zip Code*:
                  </Label>

                  <TextBox
                    block
                    id={id('zip')}
                    name="zip"
                    value={data.zip}
                    onChange={handleChange}
                    maxLength={5}
                    pattern="[0-9]{5}"
                  />
                </FormGroup>
              </Grid.Box>
            </Grid.Bounds>
          </Grid.Provider>
        </Section>

        <ButtonActionGroup>
          <Button className="button button-primary" type="submit" onClick={handleSubmit}>
            Verify Me
          </Button>
        </ButtonActionGroup>
        <ButtonActionGroup>
          <Link className="button button-primary"  to="/login.php?action=logout" onClick={onLogOutClick}>
            Log out
          </Link>
        </ButtonActionGroup>
      </form>
    </Fragment>
  );
}
