import React, { Fragment, useRef, useEffect } from 'react';

import Button from '../ui/Button';
import ButtonActionGroup from '../ui/ButtonActionGroup';
import IdentityUpload from './IdentityUpload';

import { FaIdCard, FaChevronLeft } from 'react-icons/fa';
import Section from '../ui/Section';
import { css } from 'emotion';
import { id } from '../../utils';
import { Link } from 'react-router-dom';

export default function IdentityUploadForm({
  data,
  validation,
  onDataChange,
  onSubmit,
  onBack,
  identityType,
  onLogOutClick,
  onFileRejected,
  onFileSelected
}) {
  function handleSubmit(evt) {
    evt.preventDefault();

    onSubmit(data);
  }

  const container = useRef(null);

  useEffect(() => {
    if (container.current) {
      // Force a reflow
      void container.current.offsetHeight;
    }
  });

  return (
    <Fragment>
      <form className={id('form-age-upload')} ref={container}>
        <Section>
          <button
            type="button"
            onClick={onBack}
            class="its-vt-button its-vt-change-id-type-button"
          >
            <FaChevronLeft
              size="16"
              className={css`
                margin-right: 0.25rem;
              `}
            />{' '}
            Change Identification Type
          </button>
          <IdentityUpload
            name="front"
            label={identityType.instructions}
            icon={FaIdCard}
            validation={validation.front}
            onFileSelected={file => {
              onDataChange({ ...data, front: file });
              onFileSelected(file);
            }}
            onFileRejected={onFileRejected}
            currentFile={data.front}
          />
        </Section>

        <ButtonActionGroup>
          <Button className="button button-primary" type="submit" onClick={handleSubmit}>
            Verify my age
          </Button>
        </ButtonActionGroup>
        <ButtonActionGroup>
          <Link className="button button-primary"  to="/login.php?action=logout" onClick={onLogOutClick}>
            Log out
          </Link>
        </ButtonActionGroup>
      </form>
    </Fragment>
  );
}
