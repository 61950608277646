import React from 'react';
import { css, cx } from 'emotion';
import { id } from '../../utils';
import styled from 'styled-components';

const StyledSelect = styled.select`
  background: transparent;
  border: 1px solid #fff; 
  color: #fff;
  :focus {
    border: 2px solid white;
  }
`

export default function Select({ className, children, block, ...props }) {
  return (
    <StyledSelect
      {...props}
      className={cx(
        id('select'),
        className,
        css`
          box-sizing: border-box;
        `,
        block
          ? css`
              display: block;
              width: 100%;
            `
          : css`
              display: inline-block;
            `,
        'form-select'
      )}
    >
      {children}
    </StyledSelect>
  );
}
